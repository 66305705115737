import {Component, ElementRef, ViewChild} from '@angular/core';
import {OrdersService} from "../orders.service";
import {CurrencyPipe, DatePipe, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {MerchantService} from "../../shared/services/merchant.service";
import {UserService} from "../../shared/services/user.service";
import {NgxMaskModule} from "ngx-mask";
import {NgxPrintElementModule} from "ngx-print-element";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {SharedModule} from "../../shared/shared.module";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CookieService} from "../../shared/services/cookie.service";
import * as querystring from "querystring";
import {Observable} from "rxjs";
import {DeliverymanService} from "../../shared/services/deliveryman.service";
import {ToastService} from "../../shared/services/toast.service";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-orders-details-modal',
  templateUrl: './orders-details-modal.component.html',
  styleUrls: ['./orders-details-modal.component.css'],
  imports: [
    DatePipe,
    NgIf,
    NgxMaskModule,
    CurrencyPipe,
    NgForOf,
    NgxPrintElementModule,
    SweetAlert2Module,
    SharedModule,
    JsonPipe,
    FormsModule
  ],
  standalone: true
})
export class OrdersDetailsModalComponent {


  deliveryman: any;
  deliverymanIsActive: any;

  request$: Observable<any>;

  i9Config: any;
  loadingI9: boolean;
  i9ValueDeliveryText: string;
  i9TimeDeliveryText: string;
  checkCodeI9: string;
  showInfoI9: boolean;
  statusI9: string;
  i9Request: any;

  @ViewChild('modalI9', { static: true }) modalI9: ElementRef;


  modalActive: any;
  deliverymanSelect: any;
  loadingDeliveryman: boolean;

  constructor(
    public orderService: OrdersService,
    public userService: UserService,
    private cookieService: CookieService,
    private deliverymanService: DeliverymanService,
    private ngbModal: NgbModal,
    private toast: ToastService
  )
  {
    this.loadingI9 = false;
    this.request$ = new Observable<any>();
    this.i9TimeDeliveryText = '';
    this.i9ValueDeliveryText = '';
    this.modalI9 = <ElementRef>{};
    this.showInfoI9 = false;
    this.statusI9 = 'Solicitado';
    this.deliverymanSelect = '';
    this.loadingDeliveryman = false;
    this.checkCodeI9 = '';
  }

  ngOnInit()
  {
    this.getDeliverymanOrder();
    this.getRequestI9Delivery();
    this.getConfigs();
  }

  getDeliverymanOrder(): void
  {
    this.request$ = this.deliverymanService.getDeliverymanOrder(this.orderService.orderView.order.order_id);
    this.request$.subscribe(data => {
      console.log(data);
      if (data.code === 1)
      {
        this.deliverymanSelect = data.details.deliverymanId;
      }
    }, error => {

    }, () => {

    })
  }

  getRequestI9Delivery():void
  {
    this.request$ = this.deliverymanService.getRequestI9Delivery(this.orderService.orderView.order.order_id);
    this.request$.subscribe(data => {
      console.log(data);
      if (data.code === 1)
      {
        this.i9Request = data.details;
        this.showInfoI9 = true;
        this.statusI9 = this.i9Request.status;
        this.checkCodeI9 = this.i9Request.checkCode;
      }
    }, error => {
      console.log(error);
    }, () => {

    })
  }



  getConfigs()
  {
    this.deliveryman = this.cookieService.getCookie('deliveryman');
    if (this.deliveryman)
    {
      this.deliveryman = JSON.parse(this.deliveryman);
    }
    this.deliverymanIsActive = this.cookieService.getCookie('deliverymanIsActive');
    if (this.deliverymanIsActive)
    {
      this.deliverymanIsActive = JSON.parse(this.deliverymanIsActive);
    }

    this.i9Config = this.cookieService.getCookie('i9Config');
    if (this.deliverymanIsActive)
    {
      this.i9Config = JSON.parse(this.i9Config);
    }
  }

  requestCheckValueDeliveryI9(): void
  {
    const params = {
      'merchantId': this.orderService.orderView.order.merchant_id,
      'orderId': this.orderService.orderView.order.order_id
    };
    this.request$ = this.deliverymanService.checkValuesI9(params);
    this.loadingI9 = true;
    this.request$.subscribe(data => {
      console.log(data);
      if (data.code === 1)
      {
        this.i9TimeDeliveryText = data.details.deliveryTime;
        this.i9ValueDeliveryText = data.details.price;

        this.modalActive = this.ngbModal.open(this.modalI9, {centered: true, backdrop: 'static'});
      }
    }, error => {
      console.log(error);
    }, () => {
      this.loadingI9 = false;
    })
  }

  requestDeliveryI9(): void
  {
    const params = {
      'merchantId': this.orderService.orderView.order.merchant_id,
      'orderId': this.orderService.orderView.order.order_id
    };
    this.request$ = this.deliverymanService.requestDeliveryI9(params);
    this.loadingI9 = true;
    this.request$.subscribe(data => {
      console.log(data);
      if (data.code === 1)
      {
        this.toast.showSuccess('Motoboy Solicitado com sucesso');
        this.showInfoI9 = true;
        this.modalActive.close();
      }
    }, error => {
      console.log(error);
    }, () => {
      this.loadingI9 = false;
    })
  }

  updateDeliverymanOrder(): void
  {
    const params = {
      'orderId': this.orderService.orderView.order.order_id,
      'deliverymanId': this.deliverymanSelect
    };
    this.request$ = this.deliverymanService.updateDeliverymanOrder(params);
    this.loadingDeliveryman = true;
    this.request$.subscribe(data => {
      console.log(data);
      if(data.code === 1)
      {
        this.toast.showSuccess(data.msg)
      }
      else
      {
        this.toast.showWarning(data.msg);
      }
    }, error => {
      console.log(error);
    }, () => {
      this.loadingDeliveryman = false;
    })
  }
}
