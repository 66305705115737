import {Component, OnDestroy} from '@angular/core';
import {OrdersService} from "../orders.service";
import {UserService} from "../../shared/services/user.service";
import {OrderList} from "../../models/order.models";

@Component({
  selector: 'app-orders-tables-finalize-modal',
  templateUrl: './orders-tables-finalize-modal.component.html',
  styleUrls: ['./orders-tables-finalize-modal.component.css']
})
export class OrdersTablesFinalizeModalComponent implements OnDestroy{

  constructor(
    public orderService: OrdersService,
    public userService: UserService
  )
  {

  }

  ngOnDestroy(): void
  {
    this.orderService.orderView = new OrderList();
  }



}
