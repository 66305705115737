import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {AddonDetails, Order, OrderList, Status} from "../models/order.models";
import {environment} from "../../environments/environment";
import {UserService} from "../shared/services/user.service";
import {FunctionsService} from "../shared/services/functions.service";
import {ToastService} from "../shared/services/toast.service";
import {OrdersDetailsModalComponent} from "./orders-details-modal/orders-details-modal.component";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {
  OrdersTablesFinalizeModalComponent
} from "./orders-tables-finalize-modal/orders-tables-finalize-modal.component";
import {
  OrdersTablesFinalizeSelectPaymentComponent
} from "./orders-tables-finalize-select-payment/orders-tables-finalize-select-payment.component";
import {CookieService} from "../shared/services/cookie.service";




@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  requestFromValidationIfood = ['ifood', 'digital_catalog', 'indoor'];

  apiUrl = environment.apiUrl+'/order-app';
  apiUrlIfood = environment.apiUrlIfood;
  headers: HttpHeaders;
  options: any;

  orders: OrderList[];
  orderList: OrderList[];
  ordersFinished: OrderList[];
  ordersProgress: OrderList[];
  ordersCanceled: OrderList[];
  ordersTables: OrderList[];
  ordersNew: OrderList[];
  orderView: OrderList;
  orderSelect: any;

  ordersTablesOpen: OrderList[];
  ordersTablesClose: OrderList[];
  loading: boolean;

  loadingList: boolean;
  listStatus: Status[];
  listStatusItem: any[];
  status$: Observable<any>;

  statusChange$: Observable<any>;
  statusChangeLoading: boolean;
  statusToChange: any;
  statusToChangeItem: any;

  interval$: any;
  orders$: Observable<any>;
  paymentSelect: string;
  paymentProviderSelect: string;
  orderChange: string;

  loadingOrderDetails: boolean;
  loadingOrderDetailsModal: boolean;

  service$: Observable<any>;
  collectionSize = 0;
  page = 1;
  limit = 10;
  modal: NgbModalRef;

  formStatusChange: FormGroup = new FormGroup({
    order_id: new FormControl(''),
    status_code: new FormControl(''),
    remarks: new FormControl('')
  });

  ifoodConfig: any;


  constructor(
    private http: HttpClient,
    private userService: UserService,
    private functions: FunctionsService,
    public toast: ToastService,
    private ngModal: NgbModal,
    private cookieService: CookieService
  )
  {
    this.interval$ = null;
    this.status$ = new Observable<any>();
    this.statusChange$ = new Observable<any>();
    this.statusChangeLoading = false;
    this.orders$ = new Observable<any>();
    this.listStatus = [];
    this.listStatusItem = [];
    this.orders = [];
    this.orderList = [];
    this.ordersFinished = [];
    this.ordersProgress = [];
    this.ordersCanceled = [];
    this.ordersTables = [];
    this.ordersNew = [];
    this.orderView = {...<OrderList>{}};
    this.loading = false;
    this.ordersTablesOpen = [];
    this.ordersTablesClose = [];
    this.headers = new HttpHeaders();
    this.updateHeader();
    this.getStatusList();
    this.paymentSelect = '';
    this.paymentProviderSelect = '';
    this.orderChange = '';
    this.loadingOrderDetails = false;
    this.loadingOrderDetailsModal = false;
    this.service$ = new Observable<any>();
    this.modal = <NgbModalRef>{};

    this.ifoodConfig = this.cookieService.getCookie('ifoodConfig')
    if (this.ifoodConfig)
    {
      this.ifoodConfig = JSON.parse(this.ifoodConfig);
    }
    this.loadingList = false;
  }

  calculateTotal(value: any, quantity: any): number
  {
    return Number(value) * Number(quantity);
  }

  getOrdersINL()
  {
    return this.ordersProgress.filter((v) => v.order.status_code === 'INL');
  }

  getOrdersACT()
  {
    return this.ordersProgress.filter((v) => v.order.status_code === 'ACT');
  }

  getOrdersPRT()
  {
    return this.ordersProgress.filter((v) => v.order.status_code === 'PRT');
  }

  getOrdersSAU()
  {
    return this.ordersProgress.filter((v) => v.order.status_code === 'SAU');
  }

  async changeOrderView(order: OrderList)
  {
    this.loadingOrderDetailsModal = true;


    this.orderView = order;

    if (this.orderView.items?.length)
    {
      for(let item of this.orderView.items)
      {
        if (typeof item.addon === 'string' && item.addon !== '')
        {
          item.addon = <AddonDetails>JSON.parse(item.addon);
        }
      }
    }
    this.getNextStatus();
    setTimeout(() => {
      this.loadingOrderDetailsModal = false;
    }, 400);
  }


  getNameClientOrder(order: any): string
  {
    let name = '';

    if (order.client?.first_name)
    {
      name = ` - ${order.client.first_name} ${order.client.last_name}`
    }
    else if(order.order.clientName)
    {
      name = ` - ${order.order.clientName}`;
    }

    return name;
  }

  orderIsActive(order: any)
  {
    return this.orderView.order?.order_id === order.order.order_id;
  }


  getRequestFromOrder(requestFrom: any): string
  {
    let result = '';
    switch (requestFrom)
    {
      case 'Web App':
        result = 'App próprio';
        break;

      case 'single_mob':
        result = 'App próprio';
        break;

      case 'digital_catalog':
        result = 'Catálogo App';
        break;

      default:
        result = 'Marketplace';
        break;
    }
    return result;
  }

  getPaymentTypeOrder(cod: any): string
  {
    let result = '';
    switch (cod)
    {
      case 'pyr':
        result = 'Cartão';
        break;

      case 'cod':
        result = 'Dinheiro';
        break;

      case 'pix':
        result = 'PIX';
        break;
    }
    return result;
  }

  getTransactionType(transType: any)
  {
    let result = '';
    switch (transType)
    {
      case 'pickup':
        result = 'Retirada';
        break;

      default:
        result = 'Delivery';
        break;
    }
    return result;
  }

  getDateStatusNowOrder(status: any)
  {
    let result = this.orderView.history.filter((v) => v.status === status);
    return result[0]?.date_created ?  result[0].date_created : '';
  }

  sumTotalAndQty(total: any, qty: any)
  {
    return Number(total) * Number(qty);
  }

  getOrdersSeparate()
  {
    this.ordersProgress = this.orders.filter((v) =>(v.order.status_code !== 'CLD' && v.order.status_code !== 'FNL'));
    this.ordersFinished = this.orders.filter((v) =>(v.order.status_code === 'FNL'));
    this.ordersCanceled = this.orders.filter((v) =>(v.order.status_code === 'CLD'));

    this.ordersTables = this.orders.filter((v) => {
      if (v.order?.request_from === 'digital_catalog')
      {
        return v;
      }
      if ( v.order?.request_from === 'Catálogo App')
      {
        return v;
      }
      return;
    });

    /*console.log(this.service.orders);
      console.log(this.service.ordersProgress);
      console.log(this.service.ordersFinished);
      console.log(this.service.ordersCanceled);
      console.log(this.service.ordersTables);*/
  }

  getNextStatus()
  {
    const statusNow = this.listStatus.filter((v) => v.cod === this.orderView.order?.status_code)[0];
    if (Number(statusNow?.sequence) < 5)
    {
      const sequence = Number(statusNow.sequence) + 1;
      this.statusToChange = <Status>this.listStatus.filter((v) => v.sequence.toString() === sequence.toString())[0];
    }
  }

  getNextStatusItem(statusCode: string)
  {
    const statusNow = this.listStatusItem.filter((v) => v.statusCode === statusCode)[0];
    if (Number(statusNow?.sequence) < 5)
    {
      const sequence = Number(statusNow.sequence) + 1;
      this.statusToChangeItem = <Status>this.listStatusItem.filter((v) => v.sequence.toString() === sequence.toString())[0];
    }
  }

  orderCancel(): void
  {
    this.statusToChange = {
      'cod': 'CLD',
      'description': 'Cancelado'
    };
    this.changeStatus();
  }

  changeStatusItem(obj: any): void
  {
    this.status$ = this.updateStatusItem(obj);
    this.status$.subscribe(data => {
      if (data.code === 1)
      {
        this.toast.showSuccess(data.msg, 'OK');
        for (let order of this.ordersTablesOpen)
        {
          if (order.order.order_id === obj.order_id)
          {
            order.order.status_code = this.statusToChangeItem.statusCode;
            order.order.status = this.statusToChangeItem.title;
          }
        }
      }
    })
  }

  changeStatus(): void
  {
    this.formStatusChange.controls['order_id'].setValue(this.orderView.order.order_id);
    this.formStatusChange.controls['status_code'].setValue(this.statusToChange.cod);
    this.statusChange$ = this.updateStatusOrder(this.formStatusChange.value);
    this.statusChangeLoading = true;
    this.statusChange$.subscribe(data => {
      if (data.code === 1)
      {
        this.toast.showSuccess(data.msg, 'OK');
        for (let order of this.orders)
        {
          if (order.order.order_id === this.orderView.order.order_id)
          {
            console.log('alterando ')
            order.order.status_code = this.statusToChange.cod;
            order.order.status = this.statusToChange.description;
            this.orderView.order.status_code = this.statusToChange.cod;
            this.orderView.order.status = this.statusToChange.description;

          }
        }

        for (let order of this.orderList)
        {
          if (order.order.order_id === this.orderView.order.order_id)
          {
            order.order.status_code = this.statusToChange.cod;
            order.order.status = this.statusToChange.description;
          }
        }
        this.getNextStatus();
        this.getOrdersSeparate();
      }
      else
      {
        this.toast.showDanger(data.msg, 'ERRO');
      }
      this.formStatusChange.controls['remarks'].reset()
    }, error => {
      this.statusChangeLoading = false;
      this.toast.showDanger('ERRO');
      console.log(error);
    }, () => {
      this.statusChangeLoading = false;
      this.formStatusChange.controls['remarks'].reset()
    })

  }

  openOrder(order: any): void
  {
    this.orderSelect = order;
    this.functions.redirect('orders/details');
  }


  getStatusList(): void
  {
    this.status$ = this.getStatus();
    this.status$.subscribe(data => {
      if (data.code === 1)
      {
        this.listStatus = data.details;
      }
    })
  }

  getStatusListItem(): void
  {
    this.status$ = this.getStatusItem();
    this.status$.subscribe(data => {
      if (data.code === 1)
      {
        this.listStatusItem = data.details;
      }
    })
  }

  getAllOrderTableOpen(loading = true)
  {
    this.orders$ = this.getOrdersTableOpen();
    this.loading = loading;
    this.orders$.subscribe(data => {
      if (data.code === 1)
      {
        this.playAudioTables();
        this.ordersTablesOpen = data.details;
        this.verifyIfIsNewBadgeInAllTable();
      }
      else
      {
        this.ordersTablesOpen = [];
      }
    }, error => {
      console.log(error);
      this.loading = false;
    }, () => {
      this.loading = false;
    })
  }

  getAllOrderTableClose(loading = true)
  {
    this.orders$ = this.getOrdersTableClose();
    this.loading = loading;
    this.orders$.subscribe(data => {
      console.log('table close');
      console.log(data);
      if (data.code === 1)
      {
        this.ordersTablesClose = data.details;
      }
    }, error => {
      console.log(error);
      this.loading = false;
    }, () => {
      this.loading = false;
    })
  }

  verifyIfIsNewBadgeTable(items: any): boolean
  {
    let isNew = false;
    if (items.length)
    {
      for (const item of items)
      {
        if (item.statusCode === 'INL')
        {
          isNew = true;
          break;
        }
      }
    }

    return isNew;
  }

  verifyIfIsNewBadgeInAllTable(): boolean
  {
    let isNew = false;
    if (this.ordersTablesOpen.length)
    {
      for(const table of this.ordersTablesOpen)
      {
        if (table.items.length)
        {
          for (const item of table.items)
          {
            if (item.statusCode === 'INL')
            {
              isNew = true;
              break;
            }
          }
        }
      }
    }

    return isNew;
  }


  createInterval(): void
  {
    console.log('Intervalo Criado');
    this.interval$ = setInterval(() => {
      if (Number(this.ifoodConfig) === 1)
      {
        this.getNewOrdersIfoodExec();
      }
      this.getNewOrder();
      setTimeout(() => {
        this.getAllOrderTableOpen();
        this.getAllOrderTableClose();
      },5000)
    }, 20000)
  }

  destroyInterval(): void
  {
    if(this.interval$)
    {
      clearInterval(this.interval$);
    }
  }

  getNewOrder(): void
  {
    this.orders$ = this.getNewOrders();
    this.orders$.subscribe(data => {
      if (data.code === 1)
      {
        this.playAudio().then(() => {
          this.toast.showSuccess('Existe novos pedidos');
          this.ordersNew = data.details;
          let foundOrder = false;
          for(let orderNew of this.ordersNew)
          {
            for(let order of this.orders)
            {
              if (order.order.order_id == orderNew.order.order_id)
              {
                foundOrder = true;
                break;
              }
            }
            if(!foundOrder)
            {
              this.orders.unshift(orderNew);
              foundOrder = false;
            }
          }

          this.orders.concat(this.ordersNew);
          console.log(this.orders);
          this.getOrdersSeparate();
        });

      }
      else
      {
        this.ordersNew = [];
      }
    })
  }

  getNewOrdersIfoodExec(): void
  {
    this.orders$ = this.getNewOrdersIfood();
    this.orders$.subscribe(data => {
      console.log('get new order Ifood');
      console.log(data);
    }, error => {
      console.log(error);
    })
  }

  orderTableFinalizeSelectPayment(): void
  {
    this.ngModal.open(OrdersTablesFinalizeSelectPaymentComponent, {backdrop: 'static'});
  }

  orderTableFinalize(): void
  {
    const data = {
      'orderData': this.orderView,
      'payment_select': this.paymentSelect,
      'provider_select': this.paymentProviderSelect,
      'change_order': this.orderChange
    };
    this.orders$ = this.finalizeOrderTableRequest(data);
    this.orders$.subscribe(data => {
      console.log(data);
      if (data.code === 1)
      {
        this.toast.showSuccess(data.msg);
        this.ngModal.dismissAll();
        this.getAllOrderTableOpen();
      }
      else
      {
        this.toast.showWarning(data.msg);
      }
    }, error => {
      console.log(error)
      this.toast.showDanger('Houve um problema ao conectar com o servidor, contate um administrador');
    })
  }

  async playAudio(){
    try {
      const audio = new Audio();
      audio.src = "assets/sound/notify.mp3";
      audio.load();
      return await audio.play();
    } catch (err){
      console.log(err);
    }


  }

  playAudioTables(){
    const audio = new Audio();
    audio.src = "assets/sound/cardapio-toque01.mp3";
    audio.load();
    audio.play().then(() => {

    })
  }


  getClassStyleStatus(statusCode: string)
  {
    let style = '';
    switch (statusCode)
    {
      case 'ACT':
        style = 'badge-preparo';
        break;

      case 'PRT':
        style = 'badge-pronto';
        break;

      case 'SAU':
        style = 'badge-entrega';
        break;

      case 'FNL':
        style = 'badge-finalizado';
        break;

      default:
        style = 'badge-novo';
        break;
    }

    return style;
  }

  generateTicketCopyList(order: any): void
  {
    this.changeOrderView(order).then(() => {
      this.generateTicketCopy();
    });
  }

  /*AÇÕES DE COPIAS DO PEDIDO*/
  generateTicketCopy(): void
  {
    let order = this.orderView.order;

    let d = new Date(order.date_created);
    let date = `${d.getDate()}/${d.getMonth()}/${d.getFullYear()} - ${d.getHours()}:${d.getMinutes()}`;

    let form_payment = '';

    if (order.payment_type === 'cod')
    {
      form_payment = `DINHEIRO `
      if (order.order_change && order.order_change > 0)
      {
        form_payment += `- *Troco para ${this.functions.prettyPrice(order.order_change)}*`;
      }
    }
    else if (order.payment_type === 'pyr')
    {
      form_payment = `Cartão - *${order.payment_provider_name}*`
    }

    let delivery_type = '';
    if (order.trans_type === 'delivery')
    {
      delivery_type = 'Entregar';
    }
    else
    {
      delivery_type = "Retirar";
    }

    let amazingText = `
----------------------------------
CLICK MIX DELIVERY

Pedido para *${delivery_type}*
#${order.order_id} - ${date}
${Number(order.order_id_display_ifood) > 0 ? '#IFOOD ID: '+order.order_id_display_ifood : ''}
----------------------------------
>> CLIENTE`;
    //VERIFCAÇÃO CLIENTE
    if (this.requestFromValidationIfood.includes(order.request_from.toLowerCase()))
    {
      let clientInfo = order.clientInfo;
      if (typeof order.clientInfo === 'string' && order.clientInfo !== '')
      {
        clientInfo = JSON.parse(order.clientInfo);
      }
      console.log(clientInfo);

      amazingText +=`
*${clientInfo.name}*
${clientInfo.phone.number + ' Localizer: ' + clientInfo.phone.localizer}
----------------------------------`;
    }
    else
    {
      amazingText +=`
*${this.orderView.client.first_name} ${this.orderView.client.last_name}*
${this.orderView.client.email_address}
${this.functions.maskPhone(this.orderView.client.contact_phone)}
----------------------------------`;
    }

    //VERIFICAÇÃO PARA EXIBIÇÃO DO ENDEREÇO
    if (order.trans_type === 'delivery')
    {
      amazingText +=`
>> ENDEREÇO PARA ENTREGA:
*${this.orderView.address.street}, ${this.orderView.address.number}*
${this.orderView.address.complemento ? this.orderView.address.complemento + ' - ' : ''}${this.orderView.address.reference ? this.orderView.address.reference + ' - ' : ''}${this.orderView.address.bairro} - ${this.orderView.address.city} - ${this.orderView.address.state}
----------------------------------`;
    }
    //FORMA DE PAGAMENTO
    amazingText +=`
>> MEIO PAGAMENTO
${form_payment}

==========================
`;			/*PRODUTOS*/
    if (this.orderView.items)
    {
      amazingText += `
>> PRODUTOS	`;
      for (let v of this.orderView.items)
      {
        amazingText += `
${v.qty} x *${v.size ? v.size : ''}*${v.item_name}* - ${this.functions.prettyPrice(v.discounted_price)}`;
        let addon = v.addon;
        if (typeof v.addon === 'string' && v.addon !== '') {
          addon = JSON.parse(v.addon);
        }
        if (addon)
        {
          for(let value of addon)
          {
            amazingText += `
- ${value.addon_qty} x ${value.addon_price ? value.addon_price + ' -': ''} ${value.addon_name}`;
          }
        } `
${v.order_notes ? 'Observação: ' + v.order_notes : ''}
					`
      }
    }

    //SUBTOTAL E TOTAL
    amazingText +=`
${order.delivery_instruction ? 'Observação do Pedido: '+order.delivery_instruction : ''}
Sub Total ${this.functions.prettyPrice(order.sub_total)}
Taxa de entrega ${this.functions.prettyPrice(order.delivery_charge)}
--------------------------------------
*TOTAL ${this.functions.prettyPrice(order.total_w_tax)}*
`;

    /*LOCALIZAÇÃO*/
    if (this.orderView.address)
    {
      amazingText +=`
>> LOCALIZAÇÃO:
https://www.google.com.br/maps/place/${encodeURI(this.orderView.address.street + '+' +this.orderView.address.number + '+' + this.orderView.address.bairro + '+' + this.orderView.address.city + '+' + this.orderView.address.state)}
`;
    }

    navigator.clipboard.writeText(amazingText).then(r => {
      this.toast.showSuccess('Pedido copiado!')
    });
  }

  generateTicketClientAddressCopyList(order: any)
  {
    this.changeOrderView(order).then(() => {
      this.generateTicketClientAddressCopy();
    })
  }

  generateTicketClientAddressCopy(): void {
    let order = this.orderView.order;
    let d = new Date(order.date_created);
    let date = `${d.getDate()}/${d.getMonth()}/${d.getFullYear()} - ${d.getHours()}:${d.getMinutes()}`;
    let amazingText = '';

//VERIFCAÇÃO CLIENTE
    if (this.requestFromValidationIfood.includes(order.request_from.toLowerCase()))
    {
      let clientInfo = order.clientInfo;
      if (typeof order.clientInfo === 'string' && order.clientInfo !== '')
      {
        clientInfo = JSON.parse(order.clientInfo);
      }
      amazingText +=`
*${clientInfo.name}*
`;
    }
    else
    {
      amazingText +=`
*${this.orderView.client.first_name} ${this.orderView.client.last_name}*
`;
    }

    if (order.trans_type === 'delivery')
    {
      amazingText +=`
*${this.orderView.address.street}, ${this.orderView.address.number}*
${this.orderView.address.complemento ? this.orderView.address.complemento + ' - ' : ''}${this.orderView.address.reference ? this.orderView.address.reference + ' - ' : ''}${this.orderView.address.bairro} - ${this.orderView.address.city} - ${this.orderView.address.state}
`;
    }
    /*LOCALIZAÇÃO*/
    if (order.trans_type === 'delivery')
    {
      amazingText +=`
https://www.google.com.br/maps/place/${encodeURI(this.orderView.address.street + '+' +this.orderView.address.number + '+' + this.orderView.address.bairro + '+' + this.orderView.address.city + '+' + this.orderView.address.state)}
`;
    }

    navigator.clipboard.writeText(amazingText).then((r) => {
      this.toast.showSuccess('Endereço do pedido copiado!')
    });
  }


  openModalDetailsOrder(order: any): void
  {
    this.changeOrderView(order).then(() => {
      this.modal = this.ngModal.open(OrdersDetailsModalComponent);

      this.modal.hidden.subscribe(data => {
        this.orderView = new OrderList();
        this.getOrdersListPaginationExec(false);
      })


    });
  }

  openModalFinalizeOrderTable(order: any): void
  {
    this.changeOrderView(order).then(() => {
      this.ngModal.open(OrdersTablesFinalizeModalComponent);
    });

  }

  getOrdersListPaginationExec(loading:boolean =  true): void
  {
    const params = {
      limit: this.limit,
      page: this.page
    };
    this.service$ = this.getOrdersPagination(params);
    this.loadingList = loading;
    this.service$.subscribe({
      next: (data) => {
        console.log(data);
        this.loadingList = false;
        if (data.code === 1)
        {

          this.orderList = data.details.orders;
          for(let order of this.orderList)
          {
            if(order.order.clientInfo)
            {
              order.order.clientInfo = JSON.parse(order.order.clientInfo);
            }
          }
          this.collectionSize = data.details.total.orders
        }
      },
      error:  (error) => {
        console.log(error);
        this.loadingList = false;
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

  /*REQUISIÇÕES HTTP*/
  updateHeader(): void
  {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.userService.user?.token}`});
    this.options = { headers: this.headers };
  }

  getOrdersRecent(): Observable<any>
  {
    this.updateHeader();
    return this.http.get<any>(`${this.apiUrl}/orders/recent`, this.options);
  }

  getOrdersPagination(params: any): Observable<any>
  {
    this.updateHeader();
    return this.http.post<any>(`${this.apiUrl}/orders/pagination`, params,  this.options);
  }

  getOrdersTableOpen(): Observable<any>
  {
    this.updateHeader();
    return this.http.get<any>(`${this.apiUrl}/orders/tables/open`, this.options);
  }

  getOrdersTableClose(): Observable<any>
  {
    this.updateHeader();
    return this.http.get<any>(`${this.apiUrl}/orders/tables/close`, this.options);
  }


  getPaymentList(): Observable<any>
  {
    this.updateHeader();
    return this.http.get<any>(`${this.apiUrl}/merchant/payment/list`, this.options);
  }

  getPaymentProviderList(): Observable<any>
  {
    this.updateHeader();
    return this.http.get<any>(`${this.apiUrl}/merchant/payment/provider/list`, this.options);
  }
  getNewOrders(): Observable<any>
  {
    this.updateHeader();
    return this.http.get<any>(`${this.apiUrl}/orders/new`, this.options);
  }

  getNewOrdersIfood(): Observable<any>
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `g0s5-g3s5-K3a6-a9a12`
    });
    const options = { headers: headers };

    const merchant = this.userService.getMerchant();
    const params = {
      'merchantId': merchant.merchant_id
    };

    return this.http.post<any>(`${this.apiUrlIfood}/order/polling`,params, options);
  }

  getStatus(): Observable<any>
  {
    this.updateHeader();
    return this.http.get<any>(`${this.apiUrl}/orders/get/status`, this.options);
  }

  getStatusItem(): Observable<any>
  {
    this.updateHeader();
    return this.http.get<any>(`${this.apiUrl}/orders/get/status/item`, this.options);
  }

  updateStatusOrder(status: any): Observable<any>
  {
    this.updateHeader();
    return this.http.put<any>(`${this.apiUrl}/orders/update/status`, status,this.options);
  }

  updateStatusItem(status: any): Observable<any>
  {
    this.updateHeader();
    return this.http.put<any>(`${this.apiUrl}/orders/update/status/item`, status,this.options);
  }

  finalizeOrderTableRequest(data: any): Observable<any>
  {
    this.updateHeader();
    return this.http.post<any>(`${this.apiUrl}/orders/tables/finalize`, data, this.options);
  }

}
