
<div class="modal-body">
  <button *ngIf="activeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>
  <div class="h3">Finalizar Pedido</div>
  <div class="col-10">
    <label>Forma de Pagamento</label>
    <select  class="form-select" id="payment_type" name="payment_type" [(ngModel)]="orderService.paymentSelect">
      <option value="">Selecione a forma de pagamento</option>
      <option *ngFor="let payment of paymentList" [value]="payment.payment_code">
        {{payment.payment_name}}
      </option>
    </select>
  </div>

  <div *ngIf="orderService.paymentSelect === 'pyr'" class="col-10 my-3">
    <div class="form-check form-check-inline" *ngFor="let paymentProvider of paymentProviderList">
      <input class="form-check-input" type="radio" name="payment_provider" id="provider_{{paymentProvider.id}}" value="{{paymentProvider.payment_name}}" (change)="changeProviderPayment(paymentProvider.payment_name)">
      <label class="form-check-label" for="provider_{{paymentProvider.id}}">
        <img class="img-fluid" width="40" src="{{apiLinkImage+'/'+paymentProvider.payment_logo}}">
      </label>
    </div>
  </div>

  <div *ngIf="orderService.paymentSelect === 'cod'" class="col-10">
    <label>Troco para:</label>
    <input type="text" class="form-control" name="order_change" [(ngModel)]="orderService.orderChange" currencyMask>
  </div>


  <div class="col-10 my-3">
    <div class="h4">Total: {{orderService.orderView.order.total_w_tax | currency}}</div>
    <div *ngIf="orderService.paymentSelect === 'cod' && orderService.orderChange" class="h4">Dinheiro Recebido: {{orderService.orderChange | currency}}</div>
    <div *ngIf="orderService.paymentSelect === 'cod' && orderService.orderChange" class="h4">Troco: {{calculateChange()}}</div>
  </div>



</div>


<div class="modal-footer">
  <button type="button" class="btn btn-lg btn-success text-truncate" (click)="orderService.orderTableFinalize()">
    FINALIZAR PEDIDO
  </button>
  <button *ngIf="activeModal" type="button" class="btn btn-danger" (click)="activeModal.dismiss()">Cancelar</button>
</div>
