export class Order {
  client_id: number;
  clientName?: string;
  clientInfo: any;
  date_created: string;
  delivery_charge: number;
  merchant_id: string;
  order_change: number;
  order_id: string;
  payment_provider_name: string;
  payment_type: string;
  request_from: string;
  status: string;
  status_code: string;
  sub_total: number;
  total_w_tax: string;
  trans_type: string;
  viewed: number;
  voucher_code: string;
  voucher_amount: string;
  voucher_type: string;
  order_id_ifood: string;
  order_id_display_ifood: string;
  schedule_ifood: string;
  indoor_ifood: string;
  takeout_ifood: string;
  doc_ref: string;
  delivery_instruction: string;
  table: TableDetails;
  merchant_name_ifood: any;

  constructor() {
    this.client_id = 0;
    this.date_created = '';
    this.delivery_charge = 0;
    this.merchant_id = '';
    this.order_change = 0;
    this.order_id = '';
    this.payment_type = '';
    this.payment_provider_name = '';
    this.request_from = '';
    this.status =  '';
    this.status_code = '';
    this.sub_total = 0;
    this.total_w_tax =  '';
    this.trans_type = '';
    this.viewed = 0;
    this.voucher_code = '';
    this.voucher_type = '';
    this.voucher_amount = '';
    this.order_id_ifood = '';
    this.order_id_display_ifood = '';
    this.schedule_ifood = '';
    this.indoor_ifood = '';
    this.takeout_ifood = '';
    this.doc_ref = '';
    this.delivery_instruction = '';
    this.table = new TableDetails();
  }
}

export class TableDetails
{
  title: string;

  constructor() {
    this.title = '';
  }
}

export class OrderDetails
{
  id: string;
  item_id: string;
  item_name: string;
  order_notes: string;
  normal_price: string;
  discounted_price: string;
  size: string;
  qty: string;
  addon: any;
  status: string;
  statusCode: string;

  constructor()
  {
    this.id = '';
    this.item_id = '';
    this.item_name = '';
    this.order_notes = '';
    this.normal_price = '';
    this.discounted_price = '';
    this.size = '';
    this.qty = '';
    this.addon = '';
    this.status = '';
    this.statusCode = '';
  }
}

export class AddonDetails
{
  addon_name: string;
  addon_qty: string;
  addon_price: string;

  constructor() {
    this.addon_price = '';
    this.addon_qty = '';
    this.addon_name = '';
  }
}

export class ClientOrder
{
  client_id: number;
  contact_phone: string;
  email_address: string;
  first_name: string;
  last_name: string;
  docRef: string;
  countOrders: any;
  constructor() {
    this.client_id = 0;
    this.contact_phone = '';
    this.email_address = '';
    this.first_name = '';
    this.last_name = '';
    this.docRef = '';
  }
}

export class AddressOrder
{
  street: string;
  number: string;
  bairro: string;
  complemento: string;
  reference: string;
  city: string;
  state: string;
  google_lat: string;
  google_lng: string;

  constructor() {
    this.street = '';
    this.number = '';
    this.bairro = '';
    this.complemento = '';
    this.reference = '';
    this.city = '';
    this.state = '';
    this.google_lat = '';
    this.google_lng = '';
  }
}

export class PaymentOrder{
  value: string;
  method: string;
  methodCode: string;
  currency: string;
  type: string;
  changeMoney: string;
  prepaid: string;
  isCancel: number;

  constructor() {
    this.value = '';
    this.method = '';
    this.methodCode = '';
    this.currency = '';
    this.type = '';
    this.changeMoney = '';
    this.prepaid = '';
    this.isCancel = 0;
  }
}

export class OrderList {
  order: Order;
  client: ClientOrder;
  address: AddressOrder;
  history: StatusHistory[];
  payments: PaymentOrder[];
  items: OrderDetails[];
  table?: TableDetails;

  constructor() {
    this.order = new Order();
    this.client = new ClientOrder();
    this.address = new AddressOrder();
    this.history = [];
    this.payments = [];
    this.items = [];
    this.table = new TableDetails();
  }
}

export class StatusHistory
{
  status: string;
  remarks: string;
  date_created: string;

  constructor() {
    this.status = '';
    this.remarks = '';
    this.date_created = '';

  }
}


export class Status
{
  status_id: string;
  description: string;
  cod: string;
  sequence: string;

  constructor() {
    this.status_id = '';
    this.description = '';
    this.cod = '';
    this.sequence = '';
  }
}
