import { Injectable } from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastr: ToastrService
  ) { }

  showSuccess(title: string = '', msg: string = ''): void
  {
    this.toastr.success(title, msg);
  }

  showWarning(title: string = '', msg: string = ''): void
  {
    this.toastr.warning(title, msg);
  }

  showDanger(title: string = '', msg: string = ''): void
  {
    this.toastr.error(title, msg);
  }

}
