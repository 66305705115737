import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputMasksDirective } from './directives/input-masks.directive';
import {PipesModule} from "./pipes/pipes.module";
import {SpinnerFullScreenComponent} from "./components/spinner-full-screen/spinner-full-screen.component";
import {SpinnerScreenComponent} from "./components/spinner-screen/spinner-screen.component";
import { LoadingFullScreenComponent } from './components/loading-full-screen/loading-full-screen.component';




@NgModule({
  declarations: [
    InputMasksDirective,
    SpinnerFullScreenComponent,
    SpinnerScreenComponent,
    LoadingFullScreenComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    InputMasksDirective,
    PipesModule,
    SpinnerFullScreenComponent,
    SpinnerScreenComponent,
    LoadingFullScreenComponent
  ]
})
export class SharedModule { }
