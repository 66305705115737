<app-spinner-screen *ngIf="orderService.loadingOrderDetailsModal"></app-spinner-screen>

<div class="order-modal-header shadow bg-whitesmoke p-0" *ngIf="!orderService.loadingOrderDetailsModal && orderService.orderView.order">
  <div class="">
    <div class="order-modal-header-info px-2 px-md-3 pt-2 pt-md-3 pb-2 bg-light-info d-flex justify-content-between">
      <div><label>PEDIDO</label>
        <div class="h5 m-0"><b>{{orderService.orderView.order.order_id}}</b></div>
      </div>
      <div><label>CANAL</label>
        <div><b>{{orderService.getRequestFromOrder(orderService.orderView.order.request_from)}}</b> </div>
      </div>
      <div><label>{{orderService.orderView.order.date_created | date: "dd/MM/yyyy"}}</label>
        <div><b>{{orderService.orderView.order.date_created | date: 'HH:mm:ss'}}</b> </div>
      </div>
      <div>
        <h6>
          <span class="badge {{orderService.getClassStyleStatus(orderService.orderView.order.status_code ? orderService.orderView.order.status_code : '')}}">
            <div> {{orderService.orderView.order.status}} </div>
          </span>
        </h6>
        <div>
         <!-- <small class="">TEMPO </small>
          <b></b>-->
        </div>
      </div>
    </div>
    <div class="modal-order-header-customer px-2 px-md-3 py-2" *ngIf="orderService.orderView.order.request_from.toLowerCase() !== 'ifood'">
      <div class="">{{orderService.orderView.client.first_name}} {{orderService.orderView.client.last_name}}</div>
      <div>{{orderService.orderView.client.contact_phone ? (orderService.orderView.client.contact_phone | mask:'(00) 00000-0000') : ''}}</div>
      <div *ngIf="orderService.orderView.order.doc_ref"> CPF {{orderService.orderView.order.doc_ref}}</div>
    </div>

    <div class="modal-order-header-customer px-2 px-md-3 py-2" *ngIf="orderService.orderView.order.request_from.toLowerCase() === 'ifood'">
      <div class="">{{orderService.orderView.order.clientInfo.name}}</div>
      <div>{{orderService.orderView.order.clientInfo.phone.number}} - {{orderService.orderView.order.clientInfo.phone.localizer}}</div>
      <div *ngIf="orderService.orderView.order.doc_ref"> CPF {{orderService.orderView.order.doc_ref}}</div>
    </div>
  </div>
</div>

<div class="modal-body text-dark" *ngIf="!orderService.loadingOrderDetailsModal && orderService.orderView">

  <div class="box_cart" id="order-print">

    <table class="printer-ticket" id="order-ticket">
      <thead>
      <tr class="ticket-title text-center no-screen">
        <th colspan="3">{{userService.merchant.restaurant_name}}
          <div class="ticket-font-01 ticket-font-200">{{userService.merchant.contact_phone | mask:'(00) 00000-0000'}}</div>
          <div class="ticket-font-01 ticket-font-200">clickmix.com.br<b>/{{userService.merchant.restaurant_slug}}</b></div>
        </th>
      </tr>
      <tr class="ticket-padding ticket-line-botton no-screen">
        <th colspan="3">
          <div class="ticket-line-left ticket-font-03">
            <span class="ticket-font-00 ticket-font-200">Nº Clickmix</span>
            <b># {{orderService.orderView.order.order_id}}</b></div>
          <div class="ticket-line-right ticket-font-01 ticket-font-200">
            <b>{{orderService.orderView.order.date_created | date: '"dd/MM/yyyy"'}}</b>
            {{orderService.orderView.order.date_created | date: 'HH:mm:ss'}}
          </div>
          <div class="clearfix"></div>
          <div class="ticket-line-left ticket-font-01 ticket-font-200">Pedido para:</div>
          <div class="ticket-line-left ticket-font-01">
            <b>
              {{orderService.getTransactionType(orderService.orderView.order.trans_type)}}
            </b>
          </div>
          <div class="clearfix"></div>
          <div class="ticket-line-left ticket-font-01 ticket-font-200">Forma de Pagt.</div>
          <div class="ticket-line-left ticket-font-01">
            {{orderService.getPaymentTypeOrder(orderService.orderView.order.payment_type)}} {{orderService.orderView.order.payment_provider_name ? '- ' +orderService.orderView.order.payment_provider_name: '' }}
          </div>
        </th>
      </tr>

      <tr class="no-screen">
        <th class="ticket-font-200" colspan="3">
          <div class="ticket-font-200 tickt-div-line">DADOS DO CLIENTE</div>
          <div class="clearfix"></div>
          <div class="ticket-line-left ticket-font-01" *ngIf="orderService.orderView.order.request_from.toLowerCase() !== 'ifood'">
            <b>{{orderService.orderView.client.first_name}} {{orderService.orderView.client.last_name}}</b>
          </div>
          <div class="ticket-line-left ticket-font-01" *ngIf="orderService.orderView.order.request_from.toLowerCase() === 'ifood'">
            <b>{{orderService.orderView.order.clientInfo.name}}</b>
          </div>
          <div class="clearfix"></div>
          <div class="ticket-line-left ticket-font-01" *ngIf="orderService.orderView.order.request_from.toLowerCase() !== 'ifood'">
            {{orderService.orderView.client.contact_phone}}
          </div>
          <div class="ticket-line-left ticket-font-01" *ngIf="orderService.orderView.order.request_from.toLowerCase() === 'ifood'">
            {{orderService.orderView.order.clientInfo.phone.number}} {{ orderService.orderView.order.clientInfo.phone.localizer}}
          </div>
          <div class="clearfix"></div>
          <div class="text-left ticket-font-00" *ngIf="orderService.orderView.address">
            {{orderService.orderView.address.street}},
            {{orderService.orderView.address.number}}
            {{orderService.orderView.address.complemento ? ' - ' + orderService.orderView.address.complemento : ''}}
            {{orderService.orderView.address.reference ? ' - ' + orderService.orderView.address.reference : ''}}
            {{orderService.orderView.address.bairro}} - {{orderService.orderView.address.city}} - {{orderService.orderView.address.state}} <!--CEP-->
          </div>
          <div class="clearfix"></div>
        </th>
      </tr>
      <tr class="no-screen">
        <th class="ticket-font-200 text-center" colspan="3">
          Cupom não fiscal
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3">
          <div class="cart_iten" *ngFor="let item of orderService.orderView.items">
            <div class="detail_cart">
              <div class="iten"><b>{{item.qty}} x </b> {{item.item_name}}</div>
              <span class="price">{{item.discounted_price | currency}}</span>
            </div>
            <div class="clearfix list_addons" *ngIf="item.addon">
              <ul>
                <li *ngFor="let addon of item.addon">
                  <small>- {{addon.qty}} x {{addon.addon_name}} {{addon.addon_price ? ' + ' +(addon.addon_price | currency) : ''}}</small>
                  <span>{{addon.addon_price ? (orderService.sumTotalAndQty(addon.addon_price, addon.addon_qty) | currency) : ''}}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /end cart item box -->
          <ul class="clearfix cart_iten_total">
            <li>Subtotal<span>{{orderService.orderView.order.sub_total | currency}}</span></li>
            <li>Taxa de entrega<span>{{orderService.orderView.order.delivery_charge | currency}}</span></li>
            <li class="total">Total<span>{{orderService.orderView.order.total_w_tax | currency}}</span></li>
          </ul>
        </td>
      </tr>
      </tbody>
      <tfoot class="no-screen">
      <tr>
        <td class="ticket-clickmix" colspan="3">
          www.clickmix.com.br
        </td>
      </tr>
      </tfoot>
    </table>

  </div>
  <hr>
  <div class="form-group">
    <div class="input-group mb-3">
      <input type="text" class="form-control" placeholder="Observações" aria-label="">
      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="button"> <small>SALVAR</small> </button>
      </div>
    </div>
  </div>
  <p class="lead text-muted mt-4">Histórico do pedido</p>

  <table class="table table-sm table-hover">
    <thead>
    <tr>
      <th width='20%' class="text-secondary">Data/Hora</th>
      <th width='30%' class="text-secondary">Status</th>
      <th width='45%' class="text-secondary">Observações</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let history of orderService.orderView.history">
      <td><small class="text-muted">{{history.date_created | date: "dd/MM/yyyy"}} </small>{{history.date_created | date: "HH:mm:ss"}}</td>
      <td>{{history.status}}</td>
      <td><small *ngIf="history.remarks">{{history.remarks}}</small></td>
    </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer-order bg-gray-light-01 border-top " *ngIf="!orderService.loadingOrderDetailsModal">
  <div class="footer-options-01">
    <!--AREA DE ENTREGADOR-->
    <div
      *ngIf="deliverymanIsActive === '2' && orderService.orderView.order.status_code !== 'FNL' && orderService.orderView.order.status_code !== 'CLD' && orderService.orderView.order.status_code !== 'SAU'"
      class="form-group m-0 select-delivery-man"
    >
      <select class="form-control" name="deliverymanSelect"  (change)="updateDeliverymanOrder()" [(ngModel)]="deliverymanSelect">
        <option value="">Selecione o entregador</option>
        <option *ngFor="let d of deliveryman" value="{{d.idDeliveryman}}">{{d.name}}</option>
      </select>
    </div>

    <!--AREA DE I9 REQUISIÇÃO DE MOTOBOY-->
    <div
      *ngIf="i9Config.isActive == 1 && orderService.orderView.order.status_code !== 'FNL' && orderService.orderView.order.status_code !== 'CLD' && orderService.orderView.order.status_code !== 'SAU'"
      class="form-group m-0 select-delivery-man"
    >
      <span class="d-block" *ngIf="showInfoI9">I9 Delivery</span>
      <span class="d-block" *ngIf="showInfoI9">STATUS: <b class="i9delivery-status-delivery" >{{statusI9}}</b></span>
      <small><b>{{checkCodeI9}}</b></small>


      <button type="button" class="btn btn-sm btn-outline-info mt-1 btn-i9delivery-request" *ngIf="!showInfoI9" (click)="requestCheckValueDeliveryI9()">
        <span class="spinner-border spinner-border-sm loading" role="status" aria-hidden="true" *ngIf="loadingI9"></span>
        <i class="fas fa-motorcycle"></i> <span>Solicitar I9 Delivery</span>
      </button>
    </div>
    <div class="order-bnt-action ">
      <button type="button" class="btn btn-sm btn-primary mt-1" [print]="['order-ticket']">IMPRIMIR</button>
      <swal
        #cancelSwal
        title="Deseja cancelar o pedido {{orderService.orderView.order.order_id}}?"
        text="Isso não pode ser desfeito!"
        icon="question"
        [showCancelButton]="true"
        [focusCancel]="true"
        (confirm)="orderService.orderCancel()">
      </swal>
      <button
        *ngIf="orderService.orderView.order.status_code !== 'FNL' && orderService.orderView.order.status_code !== 'CLD'"
        [swal]="cancelSwal"
        type="button"
        class="btn btn-sm btn-outline-primary mt-1"
      >
        CANCELAR
      </button>
    </div>
  </div>
  <div class="footer-options-status bg-gray-light-02 p-md-3 p-2">
    <div class="text-center d-none d-sm-block">ALTERAR STATUS PARA</div>
    <div class="text-center">
      <button
        *ngIf="orderService.orderView.order.status_code !== 'FNL' && orderService.orderView.order.status_code !== 'CLD'"
        type="button"
        class="btn {{orderService.getClassStyleStatus(orderService.statusToChange.cod)}}"
        (click)="orderService.changeStatus()"
      >
        {{orderService.statusToChange.description}}
      </button>
    </div>
  </div>
</div>


<ng-template #modalI9 let-modalI9>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmação I9 Delivery</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalI9.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="col-6">
      <div class="h6">Tempo: <span class="font-weight-bold" [innerHTML]="i9TimeDeliveryText"></span></div>
      <div class="h6">Valor: <span class="font-weight-bold"  [innerHTML]="i9ValueDeliveryText"></span></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modalI9.dismiss('Cross click')">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="requestDeliveryI9()">
      <span class="spinner-border spinner-border-sm loading" role="status" aria-hidden="true" *ngIf="loadingI9"></span>
      Solicitar Entregador
    </button>
  </div>
</ng-template>
