import { Component } from '@angular/core';
import {OrdersService} from "../orders.service";
import {Observable} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-orders-tables-finalize-select-payment',
  templateUrl: './orders-tables-finalize-select-payment.component.html',
  styleUrls: ['./orders-tables-finalize-select-payment.component.css']
})
export class OrdersTablesFinalizeSelectPaymentComponent {

  paymentList$: Observable<any>;
  paymentProviderList$: Observable<any>;
  paymentList: any[];
  paymentProviderList: any[];


  apiLinkImage = "https://images.clickmix.net/upload";
  constructor(
    public orderService: OrdersService,
    public activeModal?: NgbActiveModal
  )
  {
    this.paymentList = [];
    this.paymentProviderList = [];
    this.paymentList$ = new Observable<any>();
    this.paymentProviderList$ = new Observable<any>();

  }

  ngOnInit()
  {
    this.getPaymentList();
    this.getPaymentProviderList();
  }

  calculateChange()
  {
    return (Number(this.orderService.orderChange) - Number(this.orderService.orderView.order.total_w_tax)).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
  }

  changeProviderPayment(provider: string)
  {
    this.orderService.paymentProviderSelect = provider;
    console.log(this.orderService.paymentProviderSelect);
  }



  getPaymentList(): void
  {
    this.paymentList$ = this.orderService.getPaymentList();
    this.paymentList$.subscribe(data => {
      if (data.code === 1)
      {
        this.paymentList = data.details;
        console.log(this.paymentList);

      }
    })
  }

  getPaymentProviderList(): void
  {
    this.paymentProviderList$ = this.orderService.getPaymentProviderList();
    this.paymentProviderList$.subscribe(data => {
      if (data.code === 1)
      {
        this.paymentProviderList = data.details;
        console.log(this.paymentProviderList);

      }
    })
  }

}
