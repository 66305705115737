<div class="order-modal-header shadow bg-whitesmoke p-0">
  <div class="">
    <div class="order-modal-header-info px-2 px-md-3 pt-2 pt-md-3 pb-2 bg-light-info d-flex justify-content-between">
      <div><label>PEDIDO</label>
        <div class="h5 m-0" *ngIf="orderService.orderView.table">
          <b>{{orderService.orderView.table.title}}</b>
        </div>
      </div>
      <div><label>CANAL</label>
        <div><b>CLICK MIX</b> </div>
      </div>
      <div><label>{{orderService.orderView.order.date_created | date: 'd/M/Y'}}</label>
        <div><b>{{orderService.orderView.order.date_created | date: 'H:m:s'}}</b> </div>
      </div>
      <div>
        <h6>
          <!--<span class="badge badge-info badge-order-status">
            <div> {{orderService.orderView.order.status}} </div>
          </span>-->
        </h6>
        <div>
          <!--<small class="">TEMPO </small>-->
          <b></b>
        </div>
      </div>
    </div>
    <div class="modal-order-header-customer px-2 px-md-3 py-2">
      <div *ngIf="orderService.orderView.order.clientName" class="">{{orderService.orderView.order.clientName}}</div>
    </div>
  </div>
</div>

<div class="modal-body text-dark">

  <div class="box_cart" id="order-print">

    <table class="printer-ticket" id="order-ticket">
      <thead>
      <tr class="ticket-title text-center no-screen">
        <th colspan="3">{{userService.merchant.restaurant_name}}
          <div class="ticket-font-01 ticket-font-200">{{userService.merchant.contact_phone | mask:'(00) 00000-0000'}}</div>
          <div class="ticket-font-01 ticket-font-200">clickmix.com.br<b>/{{userService.merchant.restaurant_slug}}</b></div>
        </th>
      </tr>
      <tr class="ticket-padding ticket-line-botton no-screen">
        <th colspan="3">
          <div class="ticket-line-left ticket-font-03">
            <span class="ticket-font-00 ticket-font-200">Nº Clickmix</span>
            <b># {{orderService.orderView.order.order_id}}</b></div>
          <div class="ticket-line-right ticket-font-01 ticket-font-200">
            <b>{{orderService.orderView.order.date_created | date: 'd/M/Y'}}</b>
            {{orderService.orderView.order.date_created | date: 'H:m:s'}}
          </div>
          <div class="clearfix"></div>
        </th>
      </tr>

      <tr class="no-screen">
        <th class="ticket-font-200 text-center" colspan="3">
          Cupom não fiscal
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3">
          <div class="cart_iten" *ngFor="let item of orderService.orderView.items">
            <div class="detail_cart">
              <div class="iten"><b>{{item.qty}} x </b> {{item.item_name}}</div>
              <span class="price">{{item.discounted_price | currency}}</span>
            </div>
            <div class="clearfix list_addons" *ngIf="item.addon">
              <ul>
                <li *ngFor="let addon of item.addon">
                  <small>- {{addon.qty}} x {{addon.addon_name}} {{addon.addon_price ? ' + ' +(addon.addon_price | currency) : ''}}</small>
                  <span>{{addon.addon_price ? (orderService.sumTotalAndQty(addon.addon_price, addon.addon_qty) | currency) : ''}}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /end cart item box -->
          <ul class="clearfix cart_iten_total">
            <li>Subtotal<span>{{orderService.orderView.order.sub_total | currency}}</span></li>
            <li>Taxa de entrega<span>{{orderService.orderView.order.delivery_charge | currency}}</span></li>
            <li class="total">Total<span>{{orderService.orderView.order.total_w_tax | currency}}</span></li>
          </ul>
        </td>
      </tr>
      </tbody>
      <tfoot class="no-screen">
      <tr>
        <td class="ticket-clickmix" colspan="3">
          www.clickmix.com.br
        </td>
      </tr>
      </tfoot>
    </table>

  </div>
  <hr>
  <div class="form-group">
    <div class="input-group mb-3">
      <input type="text" class="form-control" placeholder="Observações" aria-label="">
      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="button"> <small>SALVAR</small> </button>
      </div>
    </div>
  </div>
  <p class="lead text-muted mt-4">Histórico do pedido</p>

  <table class="table table-sm table-hover">
    <thead>
    <tr>
      <th width='20%' class="text-secondary">Data/Hora</th>
      <th width='30%' class="text-secondary">Status</th>
      <th width='45%' class="text-secondary">Observações</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let history of orderService.orderView.history">
      <td><small class="text-muted">{{history.date_created | date: "dd/M/yy"}} </small>{{history.date_created | date: "HH:mm:ss"}}</td>
      <td>{{history.status}}</td>
      <td><small *ngIf="history.remarks">{{history.remarks}}</small></td>
    </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer-order bg-gray-light-01 border-top ">
  <div class="footer-options-01">
    <div class="order-bnt-action ">
      <button type="button" class="btn btn-sm btn-primary mt-1" [print]="['order-ticket']">IMPRIMIR</button>
      <button type="button" class="btn btn-sm btn-outline-primary mt-1" (click)="orderService.orderCancel()">CANCELAR</button>
    </div>
  </div>
  <div class="footer-options-status bg-gray-light-02 p-md-3 p-2">
    <div class="text-center">
      <button type="button" class="btn btn-lg btn-success text-truncate" (click)="orderService.orderTableFinalizeSelectPayment()">
        FINALIZAR PEDIDO
      </button>
    </div>
  </div>
</div>
