import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {H} from "@angular/cdk/keycodes";

@Injectable({
  providedIn: 'root'
})
export class DeliverymanService {

  apiToken: string;
  apiUrl: string;
  headers: HttpHeaders;
  options: any;


  constructor(
    private http: HttpClient,
  )
  {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders();
    this.apiToken = 'g0s5-g3s5-K3a6-a9a12';
    this.updateHeader();
  }

  updateHeader(): void
  {
    this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.apiToken}`
      }
    );
    this.options = { headers: this.headers };
  }

  checkValuesI9(params: any): Observable<any>
  {
    return this.http.post(`${this.apiUrl}/i9delivery/delivery/check-value`, params, this.options);
  }

  requestDeliveryI9(params: any): Observable<any>
  {
    return this.http.post(`${this.apiUrl}/i9delivery/delivery/create`, params, this.options);
  }

  updateDeliverymanOrder(params: any): Observable<any>
  {
    return this.http.put(`${this.apiUrl}/order-app/orders/update/deliveryman`, params, this.options);
  }

  getDeliverymanOrder(orderId: any): Observable<any>
  {
    return this.http.get(`${this.apiUrl}/order-app/orders/get/deliveryman/${orderId}`, this.options);
  }

  getRequestI9Delivery(orderId: any): Observable<any>
  {
    return this.http.get(`${this.apiUrl}/order-app/orders/get/deliveryman/i9/${orderId}`, this.options);

  }

}
