import { Injectable } from '@angular/core';
import {User} from "../../models/user.models";
import {CookieService} from "../../shared/services/cookie.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User;
  merchant: any;

  constructor(
    private cookieService: CookieService
  )
  {
    this.user = this.getUser();
    this.merchant = this.getMerchant();
  }

  getUser(): any
  {
    if (this.cookieService.getCookie('currentUser'))
    {
      return JSON.parse(<string>this.cookieService.getCookie('currentUser'));
    }
  }

  getMerchant(): any
  {
    if (this.cookieService.getCookie('merchantInfo'))
    {
      return JSON.parse(<string>this.cookieService.getCookie('merchantInfo'));
    }
  }
}
