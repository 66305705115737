import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrettyPricePipe } from './pretty-price/pretty-price.pipe';
import {ReplaceAllPipe} from "./replace-all/replace-all.pipe";



@NgModule({
  declarations: [
    PrettyPricePipe,
    ReplaceAllPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PrettyPricePipe,
    ReplaceAllPipe,
  ]
})
export class PipesModule { }
