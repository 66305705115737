import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  prettyPrice(value: any): string
  {
    let price = '';
    if (!isNaN(value))
    {
      price = `${Number(value).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}`;
    }
    return price;
  }

  maskPhone(v:any): string
  {
    let textoAjustado = '';
    if (v)
    {
      if(v.length === 11)
      {
        const ddd = v.slice(0,2)
        const part1 = v.slice(2, 7)
        const part2 = v.slice(7,12)
        textoAjustado = `(${ddd}) ${part1}-${part2}`
      }
      else if(v.length === 10)
      {
        const ddd = v.slice(0,2)
        const part1 = v.slice(2, 6)
        const part2 = v.slice(6,11)
        textoAjustado = `(${ddd}) ${part1}-${part2}`
      }
    }
    return textoAjustado
  }

  redirect(route: string, params: any = false): void
  {
    if (params)
    {
      this.router.navigate([`/${route}`, params]);
    }
    else
    {
      this.router.navigate([`/${route}`]);
    }
  }

  refresh(route: string, param: any = false): void
  {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`${route}`, param]);
    });
  }

  toCapitalize(value: string): string
  {
    value = value.toLowerCase();
    const arr = value.split(' ');
    for(let i = 0; i < arr.length; i++)
    {
      arr[i]= arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    console.log(arr.join(' '))
    return arr.join(' ')
  }

  getUrlValue(value: string): string
  {
    value = value.toLowerCase()
    return value.replace(/\s/g, '-')
      .replace(/[^0-9a-zA-Z\-]/g, '');
  }

  verifyCompleteName(name: string): boolean
  {
    let pattern = /^[aA-zZ]+\s+[aA-zZ]+/;
    return pattern.test(name);
  }

  validateCPF(inputCPF: string): boolean
  {
    let soma = 0;
    let resto = 0;

    if(inputCPF == '00000000000') return false;

    for(let i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i-1, i)) * (11 - i);

    resto = (soma * 10) % 11;

    if((resto == 10) || (resto == 11)) resto = 0;
    if(resto != parseInt(inputCPF.substring(9, 10))) return false;

    soma = 0;
    for(let i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i-1, i))*(12-i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    return resto == parseInt(inputCPF.substring(10, 11));

  }

  validateCNPJ(value: any): boolean
  {
    if (!value) return false

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === 'string'
    const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

    // Elimina valor em formato inválido
    if (!validTypes) return false

    // Filtro inicial para entradas do tipo string
    if (isString) {
      // Limita ao máximo de 18 caracteres, para CNPJ formatado
      if (value.length > 18) return false

      // Teste Regex para veificar se é uma string apenas dígitos válida
      const digitsOnly = /^\d{14}$/.test(value)
      // Teste Regex para verificar se é uma string formatada válida
      const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)

      // Se o formato é válido, usa um truque para seguir o fluxo da validação
      if (digitsOnly || validFormat) true
      // Se não, retorna inválido
      else return false
    }

    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g)
    const numbers = Array.isArray(match) ? match.map(Number) : []

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)]
    if (items.length === 1) return false

    // Cálculo validador
    const calc = (x: any) => {
      const slice = numbers.slice(0, x)
      let factor = x - 7
      let sum = 0

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i]
        sum += n * factor--
        if (factor < 2) factor = 9
      }

      const result = 11 - (sum % 11)

      return result > 9 ? 0 : result
    }

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12)

    // Valida 1o. dígito verificador
    const digit0 = calc(12)
    if (digit0 !== digits[0]) return false

    // Valida 2o. dígito verificador
    const digit1 = calc(13)
    return digit1 === digits[1]
  }

  getAddressCEP(cep: string): Observable<any>
  {
    return this.http.get<any>(`https://brasilapi.com.br/api/cep/v1/${cep}`).pipe();
  }

  getCNPJInfo(cnpj: string): Observable<any>
  {
    return this.http.get<any>(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`).pipe();
  }



}
